const documentos = [
{ nomeDocumento : "Plano de Trabalho Instituto Ser com Amor (TC01)",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2024",
ano : 2024,
tipo : "pdf",
caminho : "PLANO_DE_TRABALHO_TC01_2024"
},
{ nomeDocumento : "Plano de Trabalho Instituto Ser com Amor (TC17)",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2024",
ano : 2024,
tipo : "pdf",
caminho : "PLANO_DE_TRABALHO_TC_17_2024"
},
{ nomeDocumento : "Demonstração do Resultado em Exercício",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2022",
ano : 2022,
tipo : "pdf",
caminho : "D_R_E. 2022"
},
{ nomeDocumento : "Balanço Patrimonial",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2022",
ano : 2022,
tipo : "pdf",
caminho : "Balanço_Patrimonial_2022"
},
{ nomeDocumento : "Balancete",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2022",
ano : 2022,
tipo : "pdf",
caminho : "Balancete_2022"
},
{ nomeDocumento : "Demonstração do Resultado em Exercício",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "D.R.E_Consolidado_2023"
},
{ nomeDocumento : "Balanço Patrimonial",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "Balanco_Consolidado_2023"
},
{ nomeDocumento : "Balancete",
pasta : "Contabeis",
start : "Documentos",
middle : " ",
end : "Contábeis",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "Balancete_Consolidado_2023"
},
{ nomeDocumento : "Ata da atual diretoria",
pasta : "Instituicao",
start : "Documentos",
middle : " da ",
end : "Instituição",
subPasta : "Ata atual diretoria",
ano : 2022,
tipo : "pdf",
caminho : "Ata_da_atual_diretoria"
},
{ nomeDocumento : "Estatuto Social",
pasta : "Instituicao",
start : "Documentos",
middle : " da ",
end : "Instituição",
subPasta : "Estatuto Social",
ano : 2024,
tipo : "pdf",
caminho : "Estatuto_Social_2024"
},
{ nomeDocumento : "1º Termo de Aditamento",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2022",
ano : 2022,
tipo : "pdf",
caminho : "1º_Termo_de_Aditamento_2022"
},
{ nomeDocumento : "Plano de Trabalho Instituto Ser com Amor",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2022",
ano : 2022,
tipo : "pdf",
caminho : "Plano_de_Trabalho_Instituto_Ser_com_Amor_2022"
},
{ nomeDocumento : "Termo de Colaboração",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2022",
ano : 2022,
tipo : "pdf",
caminho : "Termo_de_Colaboração_02_2022"
},
{ nomeDocumento : "Plano de Trabalho Instituto Ser com Amor",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2023",
ano : 2023,
tipo : "pdf",
caminho : "Plano_de_Trabalho_Instituto_Ser_com_Amor_2023"
},
{ nomeDocumento : "Termo de Colaboração (TC01)",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2024",
ano : 2024,
tipo : "pdf",
caminho : "Termo_de_Colaboracao_001_2024"
},
{ nomeDocumento : "Termo de Colaboração (TC17)",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2024",
ano : 2024,
tipo : "pdf",
caminho : "Termo de Colaboração 17 2024"
},
{ nomeDocumento : "Termo de Colaboração",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2023",
ano : 2023,
tipo : "pdf",
caminho : "Termo_de_Colaboração_15_2023"
},
{ nomeDocumento : "Relatório Anual 2023",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_ANUAL23"
},
{ nomeDocumento : "Relatório Mensal Janeiro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_JANEIRO23"
},
{ nomeDocumento : "Relatório Mensal Fevereiro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_FEVEREIRO23"
},
{ nomeDocumento : "Relatório Mensal Março",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_MARÇO23"
},
{ nomeDocumento : "Relatório Mensal Abril",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_ABRIL23"
},
{ nomeDocumento : "Relatório Mensal Maio",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_MAIO23"
},
{ nomeDocumento : "Relatório Mensal Junho",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_JUNHO23"
},
{ nomeDocumento : "Relatório Mensal Julho",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_JULHO23"
},
{ nomeDocumento : "Relatório Mensal Agosto",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_AGOSTO23"
},
{ nomeDocumento : "Relatório Mensal Setembro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_SETEMBRO23"
},
{ nomeDocumento : "Relatório Mensal Outubro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_OUTUBRO23"
},
{ nomeDocumento : "Relatório Mensal Novembro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_NOVEMBRO23"
},
{ nomeDocumento : "Relatório Mensal Dezembro",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2023",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_DEZEMBRO23"
},
{ nomeDocumento : "Plano de Trabalho | Termo de Colaboração 23 2023",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2023",
ano : 2023,
tipo : "pdf",
caminho : "Plano_de_Trabalho_Termo_de_Colaboração_23_2023"
},
{ nomeDocumento : "Termo de Colaboração 23/2023",
pasta : "Parcerias",
start : "Parcerias",
middle : " ",
end : "Públicas",
subPasta : "Prefeitura de Vinhedo - 2023",
ano : 2023,
tipo : "pdf",
caminho : "Termo_de_Colaboração_23_2023"
},
{ nomeDocumento : "Relatório Anual 2023 e Plano de Ação 2024",
    pasta : "Instituicao",
    start : "Documentos",
    middle : " da ",
    end : "Instituição",
    subPasta : "Relatório anual",
    ano : 2024,
    tipo : "pdf",
    caminho : "RELATORIO_SER_ RELATORIO_2023_e_PLANO_DE_ACAO_2024"
    },
{ nomeDocumento : "Relatório Anual 2022 e Plano de Ação 2023",
pasta : "Instituicao",
start : "Documentos",
middle : " da ",
end : "Instituição",
subPasta : "Relatório anual",
ano : 2023,
tipo : "pdf",
caminho : "RELATORIO_SER_ RELATORIO_2022_E_PLANO_DE_ACAO_2023"
},

{ nomeDocumento : "Relatório Anual (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 - TC01",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_ANUAL_TC01_2024"
},
{ nomeDocumento : "Relatório Anual (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_ANUAL_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Fevereiro/Março (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_FEVEREIRO_E_MARCO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Abril (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_ABRIL_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Maio (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_MAIO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Junho (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_JUNHO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Junho (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_JUNHO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Julho (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_JULHO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Agosto (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_AGOSTO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Setembro (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_SETEMBRO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Outubro (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_OUTUBRO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Novembro (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_NOVEMBRO_TC01_2024"
},
{ nomeDocumento : "Relatório Mensal Dezembro (TC01)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 01)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_DEZEMBRO_TC01_2024"
},
{ nomeDocumento : "Relatório Anual (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_ANUAL_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Maio/Junho (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_MAIO_E_JUNHO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Julho (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_JULHO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Agosto (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_AGOSTO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Setembro (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_SETEMBRO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Outubro (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_OUTUBRO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Novembro (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_NOVEMBRO_TC17_2024"
},
{ nomeDocumento : "Relatório Mensal Dezembro (TC17)",
pasta : "Relatorios",
start : "Relatórios ",
middle : "anuais e ",
end : "planos de ação",
subPasta : "Ano 2024 (Termo de Colaboração 17)",
ano : 2024,
tipo : "pdf",
caminho : "RELATORIO_DEZEMBRO_TC17_2024"
},
            
]

export default documentos
