import { BannerHome } from "./BannerHome";
import Instituto from "./Instituto";
import Atendimentos from "./Atendimentos";


export default function Home ()
{
  return (
    <>
      <BannerHome />
      <Instituto />
      <Atendimentos />
    </>
  )
}